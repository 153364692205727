<template>
  <div>
    <article class="home-article">
      <!-- color="blue lighten-4" for v-card -->
      <v-card :loading="loading" class="ma-0 pa-0">
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <h1></h1>
        <div
          class="home-img-container"
          v-if="article.media[0] && article.media[0].url.includes('jpg')"
        >
          <img class="home-article-img" :src="article.media[0].url" />
        </div>

        <!-- IF MEDIA ARRAY URL INCLUDES YOUTUBE  -->

        <div
          class="home-img-container"
          v-else-if="
            article.media[0] && article.media[0].url.includes('youtube')
          "
        >
          <a target="_blank" :href="article.media[0].url">
            <img
              class="home-article-img"
              :src="article.media[0].thumbnail.url"
            />
          </a>
        </div>
        <div
          class="home-img-container"
          v-else-if="
            article.media[0] &&
              article.provider.includes('University of Aberdeen')
          "
        >
          <img
            class="home-logo-img"
            src="../../../public/platform-logos/uoa-logo.png"
          />
        </div>

        <!-- IF MEDIA ARRAY URL INCLUDES TWITTER  -->

        <div
          class="home-img-container"
          v-else-if="
            article.media[0] && article.media[0].url.includes('twitter')
          "
        >
          <img
            class="home-logo-img"
            v-if="article.provider.includes('University of Aberdeen')"
            src="../../../public/platform-logos/uoa-logo.png"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('CodeClan')"
            src="../../../public/platform-logos/codeclan-logo.png"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('North')"
            src="../../../public/platform-logos/nescol-logo.png"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('Robert Gordon University')"
            src="https://d4ya733yr7s0y.cloudfront.net/images/made/images/uploads/general/Uni-logo-RobertGordon_730_290_80.jpg"
          />
        </div>

        <!-- IF MEDIA ARRAY URL INCLUDES PHP  -->

        <div
          class="home-img-container"
          v-else-if="article.media[0] && article.media[0].url.includes('php')"
        >
          <img
            class="home-logo-img"
            v-if="article.provider.includes('CodeClan')"
            src="../../../public/platform-logos/codeclan-logo.png"
          />
        </div>

        <!-- IF MEDIA ARRAY URL INCLUDES PNG  -->

        <div
          class="home-img-container"
          v-else-if="article.media[0] && article.media[0].url.includes('png')"
        >
          <img
            class="home-logo-img"
            v-if="article.provider.includes('CodeClan')"
            src="../../../public/platform-logos/codeclan-logo.png"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('The Data Lab')"
            src="https://pbs.twimg.com/profile_images/1143531028340006913/zB0ZwGCm_400x400.png"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('Robert Gordon University')"
            src="https://d4ya733yr7s0y.cloudfront.net/images/made/images/uploads/general/Uni-logo-RobertGordon_730_290_80.jpg"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('Skills Development Scotland')"
            src="https://www.sgsss.ac.uk/wp-content/uploads/2018/06/sds-e1529501854249-300x192.png"
          />
        </div>

        <!-- IF MEDIA ARRAY URL INCLUDES HTTPS://T.CO  -->

        <div
          class="home-img-container"
          v-else-if="
            article.media[0] && article.media[0].url.includes('https://t.co')
          "
        >
          <img
            class="home-logo-img"
            v-if="article.provider.includes('The Data Lab')"
            src="https://pbs.twimg.com/profile_images/1143531028340006913/zB0ZwGCm_400x400.png"
          />
        </div>

        <!-- IF MEDIA ARRAY URL INCLUDES FACEBOOK  -->

        <div
          class="home-img-container"
          v-else-if="
            article.media[0] && article.media[0].url.includes('facebook')
          "
        >
          <img
            class="home-logo-img"
            v-if="article.provider.includes('North')"
            src="../../../public/platform-logos/nescol-logo.png"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('Robert Gordon University')"
            src="https://d4ya733yr7s0y.cloudfront.net/images/made/images/uploads/general/Uni-logo-RobertGordon_730_290_80.jpg"
          />
        </div>

        <!-- IF MEDIA ARRAY IS EMPTY -->

        <div
          class="home-img-container"
          v-else-if="article.media && article.media.length === 0"
        >
          <img
            class="home-logo-img"
            v-if="article.provider.includes('The Data Lab')"
            src="https://pbs.twimg.com/profile_images/1143531028340006913/zB0ZwGCm_400x400.png"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('CodeClan')"
            src="../../../public/platform-logos/codeclan-logo.png"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('University of Aberdeen')"
            src="../../../public/platform-logos/uoa-logo.png"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('Robert Gordon University')"
            src="https://d4ya733yr7s0y.cloudfront.net/images/made/images/uploads/general/Uni-logo-RobertGordon_730_290_80.jpg"
          />
          <img
            class="home-logo-img"
            v-if="article.provider.includes('Skills Development Scotland')"
            src="https://www.sgsss.ac.uk/wp-content/uploads/2018/06/sds-e1529501854249-300x192.png"
          />
        </div>

        <!-- ARTICLE PROVIDER AND SOCIAL MEDIA ICONS -->

        <v-card-title class="partner-title">{{
          article.provider.toUpperCase()
        }}</v-card-title>
        <v-icon
          class="fb-icon icon"
          v-if="article.platform && article.platform.includes('facebook')"
          >mdi-facebook</v-icon
        >
        <v-icon
          class="tw-icon icon"
          v-else-if="article.platform && article.platform.includes('twitter')"
          >mdi-twitter</v-icon
        >
        <v-icon
          class="yt-icon icon"
          v-else-if="article.platform && article.platform.includes('youtube')"
          >mdi-youtube</v-icon
        >
        <v-icon
          class="yt-icon icon"
          v-else-if="article.platform && article.platform.includes('instagram')"
          >mdi-instagram</v-icon
        >

        <v-card-text
          v-if="article.platform && article.platform.includes('youtube')"
          class="published-at-yt"
        >
          <v-row align="center" class="mx-0">
            <div class="grey--text">
              {{ article.publishedAt }}
            </div>
          </v-row>

          <div class="my-4 subtitle-1"></div>

          <div>
            <p
              class="home-story"
              :inner-html.prop="article.text | truncate(250)"
            ></p>
          </div>
        </v-card-text>
        <v-card-text v-else class="published-at">
          <v-row align="center" class="mx-0">
            <div class="grey--text">
              {{ article.publishedAt }}
            </div>
          </v-row>

          <div class="my-4 subtitle-1"></div>

          <div>
            <p
              class="home-story"
              :inner-html.prop="article.text | truncate(250)"
              v-linkified
            ></p>
          </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions>
          <v-btn
            color="#75bdc7"
            text
            target="_blank"
            v-if="article.platform.includes('twitter')"
            v-bind:href="
              'https://twitter.com/i/web/status/' +
                article.id.replace(/_|[twitter]/g, '')
            "
          >
            Open Link
          </v-btn>
          <v-btn
            color="#75bdc7"
            text
            target="_blank"
            v-if="article.platform.includes('facebook')"
            v-bind:href="
              'https://www.facebook.com/' +
                article.id.replace(/_|[facebook]/g, '')
            "
          >
            Open Link
          </v-btn>
          <v-btn
            color="#75bdc7"
            text
            target="_blank"
            v-if="
              article.media[0] && article.media[0].url.includes('instagram')
            "
            v-bind:href="article.media[0].url"
          >
            Open Link
          </v-btn>
          <v-btn
            color="#75bdc7"
            text
            target="_blank"
            v-else-if="
              article.media[0] && article.media[0].url.includes('youtube')
            "
            v-bind:href="article.media[0].url"
          >
            Watch
          </v-btn>
        </v-card-actions>
      </v-card>
    </article>
  </div>
</template>
<script>
import MainArticle from "@/components/article/MainArticle";

export default {
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    isExpanded: false,
    providerMapping: {
      "University of Aberdeen": "../../../public/platform-logos/uoa-logo.png"
    }
  }),
  component: {
    MainArticle: MainArticle
  },
  props: ["article", "loading"],
  mounted() {},
  methods: {
    goToArticle() {
      this.$router.push("/article/" + this.article.id).catch(() => {});
    },
    expandArticle() {
      this.isExpanded = !this.isExpanded;
    }
  },
  filters: {
    truncate: function(value, limit) {
      if (value) {
        if (value.length > limit) {
          value = value.substring(0, limit - 3) + "...";
        }
      }

      return value;
    }
  }
};
</script>
<style scoped>
@media (min-width: 320px) {
  .partner-title {
    font-size: 15px !important;
  }
}
@media (min-width: 1025px) {
  .partner-title {
    font-size: 15px !important;
  }
}

.v-card__title {
  display: inline-block !important;
  padding: 16px 8px 0px 16px !important;
}

.published-at {
  padding: 0 16px 16px 16px;
}
.published-at-yt {
  padding: 0 16px 0px 16px;
}

.icon {
  margin-right: 1rem;
  margin-bottom: 0.3rem;
}

.fb-icon {
  color: #3b5998;
}

.tw-icon {
  color: #1da1f2;
}

.yt-icon {
  color: #c4302b;
}
/* .v-dialog {
  pointer-events: none !important;
} */

.close-btn {
  position: absolute;
  left: 90%;
  /* width: 100% !important; */
  color: black !important;
}

.home-provider {
  /* Provider */

  position: static;
  width: 223px;
  height: 20px;
  left: 0px;
  top: 0px;

  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  /* color: #1b2475; */
}

.see-more {
  position: static;
  width: 76px;
  height: 20px;
  left: 0px;
  top: 202px;
  margin-bottom: 5px;
  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height, or 137% */

  display: flex;
  align-items: center;

  text-decoration-line: underline;

  /* NESCOL */

  color: #4e2a69 !important;
}

/* .accent {

  position: static;
  width: 223px;
  height: 2px;
  left: 0px;
  top: 24px;

  background: #1b2475;
} */

.home-date {
  /* Date */

  position: static;
  height: 20px;
  left: 0px;
  top: 30px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 19px;
  /* identical to box height, or 192% */

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  color: #1b2475;
}

.home-title-accent {
  position: relative;
  border-bottom: 2px solid;
  display: inline;
  padding-top: 10px;
  margin: 0;
}

.home-title {
  /* Title */

  position: static;
  width: 327px;
  left: 0px;
  top: 54px;

  font-family: "Mont", Helvetica, Arial !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px !important;
  line-height: 38px !important;
  /* or 160% */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  color: #323b3c;
}

.home-subtitle {
  /* Sub-Title */

  position: static;
  width: 327px;
  left: 0px;
  top: 174px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* or 160% */

  display: flex;
  align-items: center;

  color: #516163;
}

.home-article-img {
  /* Rectangle 6 */
  width: 100%;
  height: 166px;
  object-fit: cover;
  background-color: white;
}
.home-logo-img {
  /* Rectangle 6 */
  width: 100%;
  height: 166px;
  object-fit: contain;
  background-color: white;
}

.home-story {
  margin-bottom: 5px !important;
  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 160% */

  align-items: center;
}

.article-container {
  padding: 5px 24px 24px 24px;

  /* background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(48px); */
}

div.home-img-container {
  max-width: 100%;
}

/* .home-article {
  padding: 0 1rem 0 1rem;
} */

.home-story-accent {
  width: 100%;
  height: 2px;
  left: 0px;
  top: 20px;
}

.article-img {
  /* Rectangle 6 */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

div.img-container {
  position: relative;
  width: 100%;
  /* margin-top: -20px; */
  margin-left: 0px;
  margin-right: -20px;
  height: 400px;
}

.story {
  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 160% */

  display: flex;
  align-items: center;

  color: #516163;
}

.provider {
  /* Provider */

  position: static;
  /* width: 223px; */
  height: 20px;
  left: 0px;
  top: 0px;
  margin-bottom: 8px;
  margin-top: 10px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  color: #5c0f64;
}

.provider {
  /* Provider */

  position: static;
  /* width: 223px; */
  height: 20px;
  left: 0px;
  top: 0px;
  margin-bottom: 5px;
  margin-top: 10px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 0.16em;

  color: #5c0f64;
}

.main-article-accent {
  position: static;
  width: 100%;
  height: 2px;
  left: 0px;
  top: 20px;
  border: none;
  margin-bottom: 8px;

  background-color: #5c0f64;
}

.see-details {
  position: static;
  width: 76px;
  height: 20px;
  left: 0px;
  top: 202px;
  margin-top: 5px;

  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height, or 137% */

  display: flex;
  align-items: center;

  text-decoration-line: underline;

  /* NESCOL */

  color: #4e2a69 !important;
}
</style>
