<template>
  <div class="home">
    <div v-if="articles.length === 0">
      <v-alert v-if="!firstLoad && !loading">
        <v-row justify="center" align="center">
          <p>No data available for this partner</p>
        </v-row>
      </v-alert>
      <v-alert v-else>
        <v-row justify="center" align="center">
          <p>Loading Data</p>
          <v-btn icon loading></v-btn>
        </v-row>
      </v-alert>
    </div>
    <v-row v-else>
      <v-col
        cols="12"
        md="4"
        lg="4"
        class="article-col"
        v-for="(article, i) in articles"
        :key="'A' + i"
      >
        <v-skeleton-loader
          v-if="loading"
          :loading="loading"
          type="image, article"
        ></v-skeleton-loader>

        <NewsArticle v-else :article="article"></NewsArticle>
      </v-col>
    </v-row>
    <div
      v-if="articles && articles.length > 0 && !firstLoad"
      v-observe-visibility="handleScrolledToBottom"
    >
      <v-alert type="info" v-if="getLastPage">No more news articles</v-alert>
      <v-alert v-else>
        <v-row justify="center" align="center">
          <v-btn icon loading></v-btn>
        </v-row>
      </v-alert>
    </div>
  </div>
</template>

<script>
import NewsArticle from "@/components/article/NewsArticle";

export default {
  created() {
    this.isMobile();
  },
  data() {
    return {
      drawer: true,
      firstLoad: true,
      loading: true,
      page: 1,
      items: [{ title: "All News" }],
      partners: [
        { title: "University of Aberdeen" },
        { title: "Robert Gordon University" },
        { title: "One Tech Hub" },
        { title: "North East Scotland College" },
        { title: "CodeClan" },
        { title: "Skills Development Scotland" },
        { title: "The Data Lab" }
      ],
      fetchedPages: [],
      sections: [{ title: "News" }, { title: "Courses" }, { title: "Events" }],
      right: null,
      tag: null,
      numberOfItems: 0
    };
  },
  name: "News",
  components: {
    NewsArticle: NewsArticle
  },
  watch: {
    getProvider(partner) {
      this.$router.push({ name: "News", query: { partner } });
      if (this.articles.length === 0) {
        this.fetch();
      }
    }
  },
  computed: {
    articles() {
      var articles = JSON.parse(
        JSON.stringify(this.$store.getters["articles/getArticles"])
      );
      if (this.getProvider === null || this.getProvider === "All News") {
        return articles;
      }
      const filteredArticles = articles.filter(article =>
        article.provider.includes(this.getProvider)
      );

      return filteredArticles;
    },
    getProvider() {
      return this.$store.getters["articles/getSelectedProvider"];
    },
    getLastPage() {
      return this.$store.getters["articles/getLastPage"];
    }
  },
  methods: {
    async fetch() {
      if (this.getLastPage === true || this.firstLoad === true) {
        return;
      }

      let scope = this;

      var currentArticlesLength = JSON.parse(
        JSON.stringify(this.articles.length)
      );

      scope.fetchedPages.push(scope.page);
      // Page load count should equal to 1 which is the above record
      // If it is higher than 1 it means this page has already been loaded
      if (this.pageLoadCount(scope.page) < 2) {
        // if there are less than 10 items try and fetch up to 10
        this.$store.dispatch("articles/retrievePage", this.page).then(data => {
          if (data != null) {
            // find the difference between the articles length before the dispatch and after
            // add it to the number of items
            scope.numberOfItems += Math.abs(
              currentArticlesLength - scope.articles.length
            );
          }

          if ((data != null && data.length === 0) || scope.numberOfItems < 10) {
            scope.fetch();
          } else if (scope.numberOfItems >= 10) {
            scope.numberOfItems = 0;
          }
        });
        this.page++;
      }
    },
    handleScrolledToBottom(isVisible) {
      if (!isVisible) {
        return;
      }
      this.fetch();
    },
    isMobile() {
      if (screen.width <= 1024) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    },
    filter(item) {
      this.$store.commit("articles/setSelectedProvider", item.title);
    },
    goTo(section) {
      if (section.title === "News") {
        this.$router.push({ name: "News" });
      } else {
        this.$router.push("/" + section.title.toLowerCase());
      }
    },
    pageLoadCount(page) {
      let count = 0;

      for (var i = 0; i < this.fetchedPages.length; ++i) {
        if (this.fetchedPages[i] === page) count++;
      }

      return count;
    },
    async loadArticles() {
      let scope = this;
      // Load at least 30 articles to start with
      while (this.articles.length < 30 && !this.getLastPage) {
        scope.fetchedPages.push(scope.page);
        // Page load count should equal to 1 which is the above record
        // If it is higher than 1 it means this page has already been loaded
        if (this.pageLoadCount(scope.page) < 2) {
          await this.$store.dispatch("articles/retrievePage", scope.page);
          scope.loading = false;
          scope.page++;
        }
      }
      scope.firstLoad = false;
    }
  },
  mounted() {
    const route = this.$route;

    if (route.query && route.query.partner) {
      // Partner filtering using direct URLs
      this.$store.commit("articles/setSelectedProvider", route.query.partner);
    }

    // get 30 articles if not already in the store
    if (this.articles.length === 0) {
      this.loadArticles();
    } else {
      this.loading = false;
      this.firstLoad = false;
    }
  }
};
</script>

<style>
/* single column for homepage articles */

.filter-title-logo {
  position: absolute;
  margin-left: -10px;
  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 38px;
}

.burger-logo {
  height: auto;
  width: auto;
  max-width: 25px;
  max-height: 25px;
  margin-right: 4.7rem;
}

.filter-all-title {
  position: absolute;
  margin-left: -22px;
  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 38px;
  /* or 167% */

  display: flex;
  align-items: center;

  color: #4e2a69 !important;
}

.logo {
  height: auto;
  width: auto;
  max-width: 25px;
  max-height: 25px;
}

.burger-icon {
  color: #717171 !important;
}
.article-col {
  flex-basis: auto;
}
.filter-provider-title {
  position: absolute;
  margin-top: -3px;
  margin-left: -20px;
  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 38px;
  /* or 167% */

  display: flex;
  align-items: center;

  color: #4e2a69 !important;
}

.filter-title {
  position: absolute;
  margin-left: -100px;
  font-family: "Mont", Helvetica, Arial;
  font-style: normal;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 38px;
  /* or 167% */

  display: flex;
  align-items: center;

  color: #4e2a69 !important;
}
</style>
